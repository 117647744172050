<template>
  <div class="kol-ranking">
    <div class="page-header">
      <h2>达人合作排行</h2>
    </div>
    <div class="page-content">
      <el-card v-loading="loading" element-loading-text="数据加载中..." element-loading-spinner="el-icon-loading">
        <div slot="header" class="card-header">
          <span class="card-title">合作排行榜</span>
          <div class="card-extra">
            <span class="total-text">共 {{ pagination.total || 0 }} 条数据</span>
          </div>
        </div>
        <el-table :data="rankingList" style="width: 100%" @sort-change="handleSortChange" height="calc(100vh - 280px)" class="ranking-table" v-infinite-scroll="loadMoreData" infinite-scroll-disabled="infiniteScrollDisabled" infinite-scroll-distance="10">
          <el-table-column prop="rank" label="排名" width="80">
            <template slot-scope="scope">
              <div class="rank-cell">
                <span :class="['rank-number', scope.row.rank <= 3 ? 'top-rank' : '']">{{ scope.row.rank }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="nick_name" label="达人昵称" min-width="180">
            <template slot-scope="scope">
              <div class="kol-info" @click="openHomepage(scope.row.homepage_url)">
                <span class="kol-name">{{ scope.row.nick_name }}</span>
                <el-tooltip content="点击访问达人主页" placement="top" effect="light">
                  <i class="el-icon-link"></i>
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="cooperation_count" label="合作次数" width="120">
          </el-table-column>
          <el-table-column prop="base_amount" label="基础金额" width="140">
            <template slot-scope="scope">
              <span>¥ {{ formatAmount(scope.row.base_amount) }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="order_amount" label="下单金额" width="140">
            <template slot-scope="scope">
              <span class="order-amount">¥ {{ formatAmount(scope.row.order_amount) }}</span>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="120">
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="viewKolDetail(scope.row.kol_id)">
                查看详情
              </el-button>
            </template>
          </el-table-column>
        </el-table>

        <!-- 无数据时的提示 -->
        <div v-if="rankingList.length === 0 && !loading" class="empty-data">
          <i class="el-icon-data-line"></i>
          <p>暂无达人合作排行数据</p>
        </div>

        <!-- 加载中提示 -->
        <div v-if="isLoadingMore && !loading" class="loading-more">
          <i class="el-icon-loading"></i>
          <span>加载更多数据...</span>
        </div>

        <!-- 所有数据已加载完毕提示 -->
        <div v-if="allDataLoaded && rankingList.length > 0" class="all-loaded">
          <span>-- 已加载全部数据 --</span>
        </div>
      </el-card>
    </div>

    <!-- 返回顶部按钮 -->
    <el-backtop :visibility-height="200" :right="20" :bottom="90">
      <div class="backtop-inner">
        <i class="el-icon-caret-top"></i>
      </div>
    </el-backtop>
  </div>
</template>

<script>
import { getKolCooperationRanking } from '@/api'

export default {
  name: 'KolRanking',
  data () {
    return {
      loading: false,
      isLoadingMore: false,
      rankingList: [],
      pagination: {
        page: 1,
        page_size: 10,
        total: 0,
        total_pages: 0
      },
      allDataLoaded: false
    }
  },
  computed: {
    infiniteScrollDisabled () {
      return this.loading || this.isLoadingMore || this.allDataLoaded
    }
  },
  created () {
    this.fetchRankingData()
  },
  methods: {
    async fetchRankingData (reset = true) {
      if (reset) {
        this.loading = true
        this.pagination.page = 1
        this.rankingList = []
        this.allDataLoaded = false
      } else {
        this.isLoadingMore = true
      }

      try {
        const params = {
          page: this.pagination.page,
          page_size: this.pagination.page_size
        }

        const res = await getKolCooperationRanking(params)

        if (res.code === 200) {
          if (reset) {
            this.rankingList = res.data.list || []
          } else {
            this.rankingList = [...this.rankingList, ...(res.data.list || [])]
          }

          this.pagination = res.data.pagination || {
            page: 1,
            page_size: 10,
            total: 0,
            total_pages: 0
          }

          // 判断是否已加载全部数据
          this.allDataLoaded = this.pagination.page >= this.pagination.total_pages
        } else {
          this.$message.error(res.sub_msg || '获取达人排行数据失败')
        }
      } catch (error) {
        console.error('获取达人排行数据失败:', error)
        this.$message.error('获取达人排行数据失败，请稍后重试')
      } finally {
        this.loading = false
        this.isLoadingMore = false
      }
    },
    loadMoreData () {
      if (this.infiniteScrollDisabled) return

      if (this.pagination.page < this.pagination.total_pages) {
        this.pagination.page++
        this.fetchRankingData(false)
      }
    },
    formatAmount (amount) {
      if (!amount && amount !== 0) return '0.00'
      return parseFloat(amount).toLocaleString('zh-CN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    },
    openHomepage (url) {
      if (url) {
        window.open(url, '_blank')
      } else {
        this.$message.warning('达人主页链接不可用')
      }
    },
    viewKolDetail (kolId) {
      if (!kolId) {
        this.$message.warning('达人ID不可用')
        return
      }

      // 从kolId中提取实际的ID，例如："抖音_12345678" 中的 "12345678"
      const starId = kolId.split('_')[1]
      if (starId) {
        this.$router.push(`/kol/detail?star_id=${starId}`)
      } else {
        this.$message.warning('无法解析达人ID')
      }
    },
    handleSortChange (column) {
      // 目前接口仅支持按合作金额降序排序，此处预留排序切换功能
      console.log('Sort changed', column)
    }
  }
}
</script>

<style scoped>
.kol-ranking {
  padding: 24px;
}

.page-header {
  margin-bottom: 24px;
}

.page-header h2 {
  font-size: 24px;
  font-weight: 500;
  color: #1d1d1f;
  margin: 0;
}

.page-content {
  background-color: #ffffff;
  border-radius: 8px;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-title {
  font-size: 18px;
  font-weight: 500;
  color: #1d1d1f;
}

.card-extra {
  display: flex;
  align-items: center;
}

.total-text {
  font-size: 14px;
  color: #909399;
}

.rank-cell {
  display: flex;
  align-items: center;
  justify-content: center;
}

.rank-number {
  font-weight: 600;
  font-size: 16px;
  color: #606266;
}

.rank-number.top-rank {
  font-size: 18px;
  color: #f23e31;
}

.kol-info {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.kol-name {
  margin-right: 8px;
  color: #409EFF;
}

.kol-info:hover .kol-name {
  text-decoration: underline;
}

.order-amount {
  font-weight: 600;
  color: #f23e31;
}

.empty-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 60px 0;
  color: #909399;
}

.empty-data i {
  font-size: 48px;
  margin-bottom: 16px;
}

.loading-more {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  color: #909399;
}

.loading-more i {
  margin-right: 8px;
}

.all-loaded {
  text-align: center;
  color: #909399;
  padding: 16px;
  font-size: 14px;
}

/* 返回顶部按钮样式 */
.backtop-inner {
  height: 100%;
  width: 100%;
  background-color: #f23e31;
  box-shadow: 0 0 6px rgba(0, 0, 0, .12);
  border-radius: 50%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}

/* 适配移动端 */
@media screen and (max-width: 768px) {
  .kol-ranking {
    padding: 16px;
  }

  .page-header h2 {
    font-size: 20px;
  }

  .card-title {
    font-size: 16px;
  }

  .card-extra {
    font-size: 12px;
  }

  .rank-number {
    font-size: 14px;
  }

  .rank-number.top-rank {
    font-size: 16px;
  }
}

/* 修复表格在小屏幕上的显示 */
:deep(.el-table__fixed-right) {
  height: auto !important;
}

:deep(.el-table__fixed-body-wrapper) {
  height: auto !important;
}

/* Apple风格的表格样式 */
:deep(.el-table) {
  border-radius: 8px;
  overflow: hidden;
}

:deep(.el-table th) {
  background-color: #f5f7fa;
  font-weight: 500;
  color: #303133;
  padding: 12px 0;
}

:deep(.el-table--striped .el-table__body tr.el-table__row--striped td) {
  background-color: #fafafa;
}

:deep(.el-table__body tr:hover > td) {
  background-color: #f5f7fa !important;
}

:deep(.el-button--text) {
  color: #409EFF;
  font-weight: 500;
}

:deep(.el-button--text:hover) {
  color: #66b1ff;
}
</style>